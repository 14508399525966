require('./bootstrap');
require('./plugins/sticky/jquery.sticky');
require('./owl.carousel');
//https://wowjs.uk/docs.html
//require('./plugins/wow.js');
require('./plugins/plugins');
require('ckeditor4')
//require('./plugins/img-loader/index');
//require('./active');

//require('./jquery/jquery-2.2.4.min');
//require('./bootstrap/bootstrap.min');
//require('./bootstrap/popper.min');
